
import { defineComponent } from 'vue'
import footerData from './footerLinks.json'
import AppActions from '~/domain/constants/AppActions'
import { mapState } from 'vuex'

export default defineComponent({
  data() {
    return {
      unsubscribe: null as any,
      acknowledgeLogos: footerData.acknowledgeLogos,
      socialLinks: footerData.socialLinks,
      policies: footerData.policies,
      investors: footerData.investors,
      categoriesState: {},
    }
  },
  computed: {
    ...mapState('cookies', ['cookies', 'isBannerVisible']),
    ...mapState('common', ['footerMenu']),
    isMobileView() {
      const mobileThreshold = 768
      return this.$vuetify.breakpoint.width < mobileThreshold
    },
  },
  beforeMount() {
    this.$store.commit('cookies/initCookies', { localStorage, context: this })
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type.includes('cookies/')) {
        localStorage.setItem('cookiesPref', JSON.stringify(state.cookies))
      }
    })
    this.enableIntegrations()
  },
  destroyed() {
    if (this.unsubscribe) this.unsubscribe()
  },
  methods: {
    closePressed: function () {
      this.$store.commit('cookies/setBannerVisibility', {
        visible: false,
        localStorage,
      })
    },
    getAbsolutePath(slug: string) {
      return slug.startsWith('/') ? slug : `/${slug}`;
    },
    manageCookies(cookiesData: any, acceptedCookiesType: String) {
      let name, action

      switch (acceptedCookiesType) {
        case 'all':
          name = 'Cookies - Accept All'
          action = 'Accept All'
          break
        case 'necessary':
          name = 'Cookies - Reject All'
          action = 'Reject All'
          break
        case 'some':
          name = 'Cookies - Save'
          action = 'Accept Some'
          break
      }

      this.$store.dispatch(AppActions.TRACK_SEGMENT_EVENT, {
        context: this,
        params: {
          name,
          category: 'Cookies',
          action,
          label: 'web',
          misc: [
            {
              type: 'location',
              value: 'web',
            },
          ],
        },
      })

      this.$store.dispatch('cookies/setCookies', {
        context: this,
        //@ts-ignore
        segment: this.$segment,
        localStorage: localStorage,
        cookiesData: cookiesData,
      })
    },
    manageCookiesPressed() {
      if (this.$refs.cookiesController) {
        ;(this.$refs.cookiesController as any).isModalVisible = true
      }
    },
    enableIntegrations() {
      this.$store.dispatch('cookies/enableIntegrations', {
        context: this,
        //@ts-ignore
        segment: this.$segment,
        localStorage: localStorage,
        //@ts-ignore
        cookiesData: this.cookies,
      })
    },
    toggleCategory(name) {
      this.$set(this.categoriesState, name, !this.categoriesState[name])
    },
    shouldCategoryBeVisible(name) {
      return this.categoriesState[name] || !this.isMobileView
    },
  },
})
